import React, { useState, useEffect } from "react";
import { useUserData } from "../../../context/UserContext";
import { makeStyles } from "@material-ui/core/styles";
import {
    Box,
    Button,
    Typography,
    Grid,
    Paper,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
    FormHelperText,
    Input,
    CircularProgress,
    Backdrop,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { index, exportXlsx } from "../../../services/endpoints/report/production/endpoints";
import Content from "../../../components/Content";

export default function ProductionReport() {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);

    const [healthUnits, setHealthUnits] = useState([]);
    // const history = useHistory();
    const { userData } = useUserData();

    const {
        register,
        errors,
        handleSubmit,
        control,
        setError,
        reset,
        setValue,
        watch,
        getValues,
        clearError,
        formState,
    } = useForm({
        mode: "onBlur",
        reValidateMode: "onChange",
        defaultValues: {
            id_unidade: '',
            data_inicial: new Date('2020-01-01').toISOString().split("T")[0],
            data_final: new Date().toISOString().split("T")[0],
        },
    });

    useEffect(() => {
        const fetchAllHealthUnit = async () => {
            setIsLoading(true);
            await index()
                .then(({ data }) => {
                    setHealthUnits(data.unidades_de_saude);
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false);
                });
        };
        fetchAllHealthUnit();
        return () => { };
    }, []);

    useEffect(() => {
        if (userData.profile === '1') {
            setValue('id_unidade', userData.health_unit_id);
        }
        return () => { };
    }, [userData]);

    async function handleForm(dataForm) {
        setIsLoading(true);
        await exportXlsx(
            dataForm.id_unidade,
            dataForm.data_inicial,
            dataForm.data_final
        ).then(({ data }) => {
            const blobContent = new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
    
            const generatedUrl =
              window.URL && window.URL.createObjectURL
                ? window.URL.createObjectURL(blobContent)
                : window.webkitURL.createObjectURL(blobContent);
    
            const tempLink = document.createElement("a");
            tempLink.style.display = "none";
            tempLink.href = generatedUrl;
            tempLink.setAttribute("download", "Relatório de produtividade.xlsx");
    
            if (typeof tempLink.download === "undefined") {
              tempLink.setAttribute("target", "_blank");
            }
    
            document.body.appendChild(tempLink);
            tempLink.click();
    
            setTimeout(function () {
              document.body.removeChild(tempLink);
              window.URL.revokeObjectURL(generatedUrl);
              setIsLoading(false);
            }, 200);
          }).catch(() => {
            setIsLoading(false);
          });
    }

    return (
        <Content>
            <Backdrop className={classes.backdrop} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container className={classes.gridRoot}>
                <Box className={classes.paperStyle} component={Paper} elevation={2}>
                    <Typography component="h1" variant="h6">
                        Relatório de Produtividade de Profissionais de Saúde
                    </Typography>
                    <form
                        method="get"
                        onSubmit={handleSubmit((data) => handleForm(data))}
                    >
                        <Box className={classes.flexContainer}>
                            <FormControl error={!!errors.id_unidade} className={classes.flexItems}>
                                <InputLabel>Unidade de Saúde</InputLabel>
                                <Controller
                                    as={
                                        <Select disabled={userData.profile === '1'}>
                                            {healthUnits.map((unit, index) => (
                                                <MenuItem
                                                    key={unit.id_unidade}
                                                    value={unit.id_unidade}
                                                >
                                                    {unit.nome}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    }
                                    control={control}
                                    name="id_unidade"
                                />
                                <FormHelperText>{errors?.id_unidade?.message}</FormHelperText>
                            </FormControl>
                            <FormControl error={!!errors.data_inicial} className={classes.flexItems}>
                                <InputLabel>Data inicial</InputLabel>
                                <Input
                                    name="data_inicial"
                                    type="date"
                                    inputProps={{min:'2020-01-01', max: new Date().toISOString().split("T")[0]}}
                                    inputRef={register({ required: false })}
                                />
                                <FormHelperText>{errors?.data_inicial?.message}</FormHelperText>
                            </FormControl>
                            <FormControl error={!!errors.data_final} className={classes.flexItems}>
                                <InputLabel>Data final</InputLabel>
                                <Input
                                    name="data_final"
                                    type="date"
                                    inputProps={{min:'2020-01-01', max: new Date().toISOString().split("T")[0]}}
                                    inputRef={register({ required: false })}
                                />
                                <FormHelperText>{errors?.data_final?.message}</FormHelperText>
                            </FormControl>
                        </Box>
                        <Box className={classes.flexContainerButton}>
                            <Button
                            size="large"
                            variant="contained"
                            type="submit"
                            color="primary"
                            >
                                Exportar
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Grid>
        </Content>
    );
}

const useStyles = makeStyles((theme) => ({
    gridRoot: {},
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: theme.palette.primary.main,
    },
    paperStyle: {
        padding: theme.spacing(2),
        margin: theme.spacing(0, 2),
        marginBottom: theme.spacing(2),
        width: "100%",
    },
    flexContainer: {
        marginTop: theme.spacing(2),
        display: "flex",
        [theme.breakpoints.between("xs", "sm")]: {
            flexDirection: "column",
        },
        justifyContent: "space-between",
    },
    flexContainerButton: {
        marginTop: theme.spacing(5),
        display: "flex",
        [theme.breakpoints.between("xs", "sm")]: {
            flexDirection: "column",
        },
        justifyContent: "flex-start",
    },
    flexItems: {
        marginTop: theme.spacing(2),
        [theme.breakpoints.between("xs", "sm")]: {
            marginRight: theme.spacing(1),
        },
        [theme.breakpoints.up("md")]: {
            marginRight: theme.spacing(2),
        },
        width: "100%",
    },
}));


