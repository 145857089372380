import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot,
} from "@material-ui/lab";
import {
  Paper,
  CircularProgress,
  Typography,
  Box,
  Button,
  Backdrop,
} from "@material-ui/core";
import { confirmationAlert, defaultAlert } from "../../helpers/validation/alerts";
import UploadGovDocument from "../../components/UploadGovDocument";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import { getFormatedDateTimeUI } from "../../helpers/dateHelpers";
import { getPatientDocumentPDFReadableRegisteredByRegisterId } from "../../services/endpoints/patientHistory/endpoints";
import { deletedDocumentGov } from "../../services/endpoints/documentRegister/endpoints";
import noDataImage from "../../assets/images/no_data_history.png";

const videoDomain = process.env.REACT_APP_VIDEOIFRAME_DOMAIN;

const HistoryTimeline = ({ patientHistory, isLoading, refresh }) => {

  const classes = useStyles();
  const [isLoadingPDF, setIsLoadingPDF] = useState(false);

  const [registerId, setRegisterId] = useState(null);
  const [uploadDocumentGovDialogOpen, setUploadDocumentGovDialogOpen] = useState(false);

  function handlePDF(registerId, isDownload, filename, externoDoc = '') {
    setIsLoadingPDF(true);
    getPatientDocumentPDFReadableRegisteredByRegisterId(registerId, externoDoc)
      .then(({ data }) => {
        const blobContent = new Blob([data], { type: "application/pdf" });
        const generatedUrl =
          window.URL && window.URL.createObjectURL
            ? window.URL.createObjectURL(blobContent)
            : window.webkitURL.createObjectURL(blobContent);

        if (isDownload) {
          const tempLink = document.createElement("a");
          tempLink.style.display = "none";
          tempLink.href = generatedUrl;
          tempLink.setAttribute("download", filename);

          if (typeof tempLink.download === "undefined") {
            tempLink.setAttribute("target", "_blank");
          }

          document.body.appendChild(tempLink);
          tempLink.click();
          setIsLoadingPDF(false);

          setTimeout(function () {
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(generatedUrl);
          }, 200);
        } else {
          window.open(generatedUrl, "", "width=800,height=600");
          setIsLoadingPDF(false);
        }
      })
      .catch(() => {
        setIsLoadingPDF(false);
      });
  }

  function handleOpenUploadDocumentGovDialog(registerId, isSigned){
    if(isSigned){
      confirmationAlert({
        messageBody: 'Já existe documento assinado. Tem certeza que deseja alterar o documento?',
        isDismissible: false,
        footerMessage: `
          <div style='text-align:center'>
            <i>
              O documento anterior não será deletado, para fins de relatórios, registros, rastreabilidade e consulta será possível.
            </i>
          </div>`,
      }).then((result) => {
        if (result.value) {
          setRegisterId(registerId);
          setUploadDocumentGovDialogOpen(true);
        }else{
          setRegisterId(null);
        }
      });
    }else{
      setRegisterId(registerId);
      setUploadDocumentGovDialogOpen(true);
    }
  }

  async function handleRemoveAssGov(registerId){
    confirmationAlert({
      messageBody: 'Deseja realmente remover o arquivo assinado?',
      isDismissible: false,
    }).then(async (result) => {
      if (result.value) {
        setIsLoadingPDF(true);
        await deletedDocumentGov(registerId)
          .then(({ data }) => {
            if (data.status) {
              defaultAlert(
                data.msg,
                false,
                "OK",
                "success",
                "Tudo certo!"
              );
              refresh();
              setIsLoadingPDF(false);
            }
          })
          .catch((error) => {
            setIsLoadingPDF(false);
          });
      }
    });
  }

  return (
    <>
      {isLoading ? (
        <Box className={classes.loadingBox}>
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <Box className={classes.timelineBox}>
          <Backdrop className={classes.backdrop} open={isLoadingPDF}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Timeline className={classes.timelineComponent} align="left">
            {patientHistory && patientHistory.length > 0 ? (
              patientHistory.map((history) => (
                <TimelineItem key={history.id_registro}>
                  <TimelineOppositeContent style={{ flex: 0, padding: 0 }} />
                  <TimelineSeparator>
                    <TimelineDot color="primary">
                      <CreateNewFolderIcon />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Paper elevation={3} className={classes.paper}>
                      <Typography variant="h6" component="h1">
                        {history.nome_documento}({history.tipo_de_documento})
                      </Typography>
                      <Typography variant="button" component="p">
                        Profissional: {`${history.prestador}`}
                      </Typography>
                      {!!videoDomain ? 
                      <Typography variant="caption" component="p">
                        Procedimento: {history.procedimento_realizado}
                      </Typography> : null }
                      <Typography variant="caption" component="p">
                        Status da assinatura:{" "}
                        <text className={history.is_assinado === 'A' ? classes.statusSuccess : classes.statusDanger}>{!!history.hash_documento_assinado ? 'Assinado via certificado digital' : (history.arquivo_externo == 't' ? 'Assinado via gov.br' : 'Não assinado')}</text>
                      </Typography>
                      <Typography variant="caption" component="p">
                        Data de criação:{" "}
                        {getFormatedDateTimeUI(history.created_at)}
                      </Typography>
                      <Box className={classes.boxPDFButtons}>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handlePDF(history.id_registro, false)}
                        >
                          Visualizar
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() =>
                            handlePDF(
                              history.id_registro,
                              true,
                              history.name_pdf
                            )
                          }
                        >
                          Baixar
                        </Button>
                        {
                          !!!history.hash_documento_assinado && 
                          <>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => handleOpenUploadDocumentGovDialog(history.id_registro, history.is_assinado == 'A')}
                            >
                              UPLOAD ARQUIVO ASSINADO .GOV
                            </Button>
                            {!!history.arquivo_assinado_gov &&
                              <>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={() =>
                                    handlePDF(
                                      history.id_registro,
                                      false,
                                      history.name_pdf,
                                      'externo'
                                    )
                                  }
                                >
                                  ASSINADO .GOV
                                </Button>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={() =>
                                    handleRemoveAssGov(history.id_registro)
                                  }
                                >
                                  REMOVER ASSINADO .GOV
                                </Button>
                              </>
                            }
                          </>
                        }
                      </Box>
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
              ))
            ) : (
              <Box className={classes.loadingBox}>
                <img
                  src={noDataImage}
                  className={classes.imageNoData}
                  alt="Imagem que demonstra que o paciente não possui histórico de atendimento"
                />
                <Typography variant="h6" component="h6">
                  Paciente sem histórico para exibição!
                </Typography>
              </Box>
            )}
          </Timeline>
        </Box>
      )}
      <UploadGovDocument
        open={uploadDocumentGovDialogOpen}
        close={() => {setUploadDocumentGovDialogOpen(false); refresh();}}
        registerId={registerId}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
  },
  paper: {
    padding: "6px 16px",
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  loadingBox: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
  timelineBox: {
    height: "100%",
    maxHeight: "320px",
    overflow: "overlay",
  },
  imageNoData: {
    width: 150,
  },
  boxPDFButtons: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    gap: "10px",
    margin: theme.spacing(2, 0),
  },
  statusSuccess: {
    color: theme.status.success(900),
  },
  statusDanger: {
    color: theme.status.danger(900),
  },
}));

export default HistoryTimeline;
