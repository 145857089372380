import api from "../../api";

export const createDocumentRegister = async (params) => {
  return api.post("registro/create", params);
};

export const uploadDocumentGov = async (idRegister, params) => {
  return api.post(`registro/upload/${idRegister}`, params, {
    headers: {
      "Content-Type": `multipart/form-data;`,
    },
  });
};

export const downloadDocumentGov = async (idRegister, params) => {
  return api.get(`registro/download/${idRegister}/externo`, params, {
    responseType: "arraybuffer",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/pdf"
    },
  });
};

export const deletedDocumentGov = async (idRegister) => {
  return api.get(`registro/delete_gov/${idRegister}`);
};

export const createSignDocumentRegister = async (params) => {
  return api.post("registro/create_sign", params);
};

export const getMyDocumentsRegister = async () => {
  return api.get("registro/get_all_by_prestador");
};

export const signDocumentRegister = async (idRegister, params) => {
  return api.get(`registro/assinar/${idRegister}`, params);
};

export const sendPatientMail = async (params) => {
  return api.post(`registro/send_email`, params);
};
