import * as yup from "yup";
import ptBr from "../../../../locale/yup-translation-pt-BR";
yup.setLocale(ptBr);


export const UploadGovDocumentSchema = yup.object().shape({
  documento: yup
    .mixed()
    .label("Documento")
    .required()
    .test("is-empty", 'É necessário selecionar o arquivo .pdf para fazer o upload', function(value){
      return !!value;
    })
});
