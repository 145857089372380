import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { defaultAlert } from "../../helpers/validation/alerts";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
  Box,
  FormControl,
  InputLabel,
  FormHelperText,
  Input,
  CircularProgress,
  Backdrop,
  Link
} from "@material-ui/core";
import { uploadDocumentGov } from "../../services/endpoints/documentRegister/endpoints";
import { UploadGovDocumentSchema } from "../../helpers/validation/Schemas/UploadGovDocument/schema";
import { makeStyles } from "@material-ui/core/styles";

const UploadGovDocument = ({ open, close, registerId }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  const { errors, handleSubmit, control, setError, clearError, setValue } = useForm({
    validationSchema: UploadGovDocumentSchema,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: {
      documento: "",
    },
  });

  function handleClose() {
    close();
  }

  function validateFileSizeFileType(file){
    var inputDocumento = document.getElementById('documento');
    if (typeof inputDocumento.files[0] !== 'undefined'){

      const fileSize = inputDocumento.files[0].size / 1024 / 1024; // in MiB
      if (fileSize > 20) {
        setSubmitButtonDisabled(true);
        defaultAlert(
          'O arquivo excede o tamanho máximo permitido de 20mb.',
          true,
          'Entendi',
          'question'
        );
      }else{
        if(inputDocumento.files[0].name.split('.').pop() !== 'pdf'){
          setSubmitButtonDisabled(true);
          defaultAlert(
            'O arquivo possui formato inválido. Altere para o formato .pdf e tente novamente.',
            true,
            'OK',
            'question'
          );
        }else{
          setSubmitButtonDisabled(false);
        }
      }
    }
  }

  async function handleForm(data) {
    setIsLoading(true);

    const formData = new FormData();
    var documento = document.querySelector('#documento');
    formData.append('documento', documento.files[0]);

    await uploadDocumentGov(registerId, formData)
      .then(({ data }) => {
        if (data.status) {
          defaultAlert(
            data.msg,
            false,
            "OK",
            "success",
            "Tudo certo!"
          ).then((result) => {
            if (result.value) {
              close();
            }
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }
  return (
    <>
      <Dialog
        scroll="paper"
        open={open}
        fullWidth={true}
        maxWidth="sm"
        style={{ zIndex: 1 }}
        aria-labelledby="Upload documento assinado via GOV.BR"
        aria-describedby="Caixa de dialogo para importar documento assinado via GOV.BR"
      >
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <form
          method="POST"
          encType="multipart/form-data"
          onSubmit={handleSubmit((data) => handleForm(data))}
        >
          <DialogTitle id="alert-dialog-title">
            Importar documento assinado
          </DialogTitle>
          <DialogContent dividers={true}>
            <DialogContentText>
              Para realizar a importação, primeiro assine o documento no assinador &nbsp;
              <Link href="https://assinador.iti.br" target="_blank" rel="noopener" >
                assinador.iti.br
              </Link>
              &nbsp; do gov.br
            </DialogContentText>
            <Box className={classes.flexContainer}>
              <FormControl error={!!errors.documento} className={classes.flexItems}>
                <InputLabel>Documento</InputLabel>
                <Controller
                  as={<Input />}
                  id="documento"
                  type="file"
                  accept="application/pdf"
                  rules={{ required: true }}
                  control={control}
                  name="documento"
                  onChange={(e) => {
                    // eslint-disable-next-line no-lone-blocks
                    {
                      validateFileSizeFileType(e);
                    }
                    return e[0];
                  }}
                />
                <FormHelperText>{errors?.documento?.message}</FormHelperText>
              </FormControl>
            </Box>
            <br></br><br></br><br></br>
          </DialogContent>
          <DialogActions>
            <Button
              size="large"
              variant="contained"
              type="submit"
              color="primary"
              disabled={submitButtonDisabled}
            >
              Importar
            </Button>
            <Button onClick={handleClose} color="default">
              Fechar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
const useStyles = makeStyles((theme) => ({
  gridRoot: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
  },
  paperStyle: {
    padding: theme.spacing(2),
    margin: theme.spacing(0, 2),
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  flexContainer: {
    marginTop: theme.spacing(2),
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    justifyContent: "space-between",
  },
  flexContainerButton: {
    marginTop: theme.spacing(5),
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    justifyContent: "flex-start",
    width: "100%",
    gap: "10px",
    margin: theme.spacing(2, 0),
  },
  flexItems: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.between("xs", "sm")]: {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(2),
    },
    width: "100%",
  },
}));
export default UploadGovDocument;
