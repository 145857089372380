import React, { useState, useEffect } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import {
  Grid,
  Box,
  Typography,
  Paper,
  Button,
  Tab,
  AppBar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Icon,
  CircularProgress,
} from "@material-ui/core";
import Content from "../../components/Content";
import Swal from "sweetalert2";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import HealthRecordField from "../../components/HealthRecordField";
import HistoryTimeline from "../../components/HistoryTimeline";
import VideoIframe from "../../components/VideoIframe";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  defaultAlert,
  confirmationAlert,
  confirmationSendMailAlert
} from "../../helpers/validation/alerts";
import {
  getDocumentsShortList,
  getDocumentFields,
} from "../../services/endpoints/documents/endpoints";
import { setFinalizeConsult } from "../../services/endpoints/scheduling/endpoints";
import {
  createDocumentRegister,
  createSignDocumentRegister,
  sendPatientMail
} from "../../services/endpoints/documentRegister/endpoints";
import { getHistoryByPatientId } from "../../services/endpoints/patientHistory/endpoints";
import { checkSignatureService } from "../../services/endpoints/systemParameters/endpoints";
import { getAgeByDate, getFormatedDateUI } from "../../helpers/dateHelpers";
import { getCpfFormated } from "../../helpers/stringHelpers";
import { useUserData } from "../../context/UserContext";
import { checkCertificateSign } from "../../services/endpoints/users/endpoints";
import Noty from "noty";
import "../../styles/metroui.css";
import "../../styles/noty.css";
import imgRecord from "../../assets/images/patient_record.png";
import govLogo from "../../assets/images/govbr-logo.png";
import io from "socket.io-client";
import { getPatientFilesList } from "../../services/endpoints/patients/endpoints";
import FileList from "../../components/FileList";

var socket;
const videoDomain = process.env.REACT_APP_VIDEOIFRAME_DOMAIN;

export default function TeleConsulta() {
  const classes = useStyles();
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();
  const { userData } = useUserData();

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [scheduleId, setScheduleId] = useState("");
  const [patient, setPatient] = useState({});
  const [isRoomActive, setIsRoomActive] = useState(true);
  const [tabValueSelected, setTabValueSelected] = useState("1");
  const [documents, setDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState("");
  const [templateFields, setTemplateFields] = useState({});
  const [fields, setFields] = useState({});
  const [patientHistory, setPatientHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingDynamicInputs, setIsLoadingDynamicInputs] = useState(false);
  const [roomInternalId, setRoomInternalId] = useState("");
  const [files, setFiles] = useState([]);
  const [signatureServiceStatus, setSignatureServiceStatus] = useState(false);
  const [userCertificateStatus, setUserCertificateStatus] = useState(false);
  const [canSign, setCanSign] = useState(false);
  const [user, setUser] = useState({});

  useEffect(() => {
    if (location?.state?.id_agenda) {
      socket = !!process.env.REACT_APP_SOCKET_DOMAIN
        ? io(process.env.REACT_APP_SOCKET_DOMAIN)
        : null;
      setScheduleId(location.state.id_agenda);
      setPatient(location.state.patientData);
      setRoomInternalId(location.state.id_sala);
      setUser(location.state.professional);
      getHistoryByPatientId(location.state.patientData.id_paciente)
        .then(({ data }) => {
          setPatientHistory(data.list);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      setIsRoomActive(false);
      defaultAlert("Atendimento já realizado!", false).then((result) => {
        if (result.value) {
          history.push("/scheduling/list", {});
        }
      });
    }
    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, [location, history]);

  useEffect(() => {
    let timeToEnableEndConsultButton = setTimeout(
      () => setIsButtonDisabled(false),
      5000
    );
    return () => {
      clearTimeout(timeToEnableEndConsultButton);
    };
  }, []);

  useEffect(() => {
    if (!!socket) {
      socket.on(`new_file_${location?.state?.id_sala}`, function (data) {
        fetchPatientFiles(location.state.patientData.id_paciente);
        setTabValueSelected("3");
        new Noty({
          text: `Novo anexo recebido!`,
          type: "success",
          theme: "metroui",
          timeout: 3000,
        }).show();
      });
    }
  }, []);

  useEffect(() => {
    const fetchDocument = () => {
      getDocumentsShortList().then(({ data }) => {
        setDocuments(data.list_documento);
      });
    };
    fetchDocument();
  }, []);

  useEffect(() => {
    if (userData.id_professional) {
      const fetchCheckSignatureService = () => {
        checkSignatureService().then(({ data }) => {
          data.status_serviço_assinatura === "A"
            ? setSignatureServiceStatus(true)
            : setSignatureServiceStatus(false);
        });
        checkCertificateSign(userData.id).then(({ data }) => {
          data.has_certificado === "A"
            ? setUserCertificateStatus(true)
            : setUserCertificateStatus(false);
        });
      };
      fetchCheckSignatureService();
    }
  }, [userData]);

  function fetchPatientFiles(patientId) {
    setIsLoading(true);
    getPatientFilesList(patientId)
      .then(({ data }) => {
        setFiles(data.list_arquivo);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }

  function fetchPatientHistory() {
    setIsLoading(true);
    getHistoryByPatientId(patient.id_paciente)
      .then(({ data }) => {
        setPatientHistory(data.list);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }

  function handleChangeTab(event, value) {
    setTabValueSelected(value);
    if (value === "1") {
      fetchPatientHistory();
    } else if (value === "3") {
      fetchPatientFiles(location.state.patientData.id_paciente);
    }
  }

  function handleSelectedDocument(e) {
    if (e.target.value !== "" && selectedDocument === "") {
      setSelectedDocument(e.target.value);
      handleDynamicInputsCreation(e.target.value);
      setIsLoadingDynamicInputs(true);
    } else if (selectedDocument !== e.target.value && e.target.value !== "") {
      confirmationAlert({
        messageBody:
          "Deseja realmente alterar o tipo de documento? Está operação é irreversível, todas as informações digitadas serão perdidas!",
        isDismissible: false,
        confirmationButtonText: "Confirmar",
        iconText: "warning",
        titleText: "ATENÇÃO!",
      }).then((result) => {
        if (result.value) {
          setFields({});
          setTemplateFields({});
          setSelectedDocument(e.target.value);
          handleDynamicInputsCreation(e.target.value);
          setIsLoadingDynamicInputs(true);
        } else {
          setSelectedDocument(selectedDocument);
        }
      });
    } else {
      setSelectedDocument("");
      setFields({});
      setTemplateFields({});
    }
  }

  function handleDynamicInputsCreation(documentId) {
    if (documentId) {
      getDocumentFields(documentId)
        .then(({ data }) => {
          setCanSign(data.assinar);
          if (Object.keys(data.documento.fields).length > 0) {
            setFields({});
            setTemplateFields(data.documento.fields);
            setIsLoadingDynamicInputs(false);
          } else {
            defaultAlert(
              "Este documento não possui campos",
              true,
              "Entendi",
              "info",
              "Atenção!"
            );
            setFields({});
            setTemplateFields({});
            setSelectedDocument("");
            setIsLoadingDynamicInputs(false);
          }
        })
        .catch(() => {
          setIsLoadingDynamicInputs(false);
        });
    }
  }

  function handleSubmitDynamicForm() {
    setIsLoadingDynamicInputs(true);
    const formData = fields;
    formData.id_documento = selectedDocument;
    formData.id_paciente = patient.id_paciente;
    formData.id_agenda = scheduleId;
    if (Object.keys(fields).length < Object.keys(templateFields).length) {
      confirmationAlert({
        messageBody:
          "Existem campos sem preenchimento, deseja salvar mesmo assim?",
        iconText: "info",
        titleText: "Atenção",
        isDismissible: false,
        confirmationButtonText: "Confirmar",
      }).then((result) => {
        if (result.value) {
          createDocumentRegister(formData)
            .then(({ data }) => {
              if (data.status) {
                defaultAlert(
                  "Documento salvo com sucesso!",
                  true,
                  "Entendi",
                  "success",
                  "Tudo certo!"
                );
                setFields({});
                setTemplateFields({});
                setSelectedDocument("");
                fetchPatientHistory();
                setIsLoadingDynamicInputs(false);
              }
            })
            .catch(() => {
              setIsLoadingDynamicInputs(false);
            });
        }
      });
    } else {
      createDocumentRegister(formData)
        .then(({ data }) => {
          if (data.status) {
            defaultAlert(
              "Documento salvo com sucesso!",
              true,
              "Entendi",
              "success",
              "Tudo certo!"
            );
            setFields({});
            setTemplateFields({});
            setSelectedDocument("");
            fetchPatientHistory();
            setIsLoadingDynamicInputs(false);
          }
        })
        .catch(() => {
          setIsLoadingDynamicInputs(false);
        });
    }
  }

  function handleSubmitSignDynamicForm() {
    setIsLoadingDynamicInputs(true);
    const formData = fields;
    formData.id_documento = selectedDocument;
    formData.id_paciente = patient.id_paciente;
    formData.id_agenda = scheduleId;

    if (Object.keys(fields).length < Object.keys(templateFields).length) {
      confirmationAlert({
        messageBody:
          "Existem campos sem preenchimento, deseja salvar mesmo assim?",
        iconText: "info",
        titleText: "Atenção",
        isDismissible: false,
        confirmationButtonText: "Confirmar",
      }).then((result) => {
        if (result.value) {
          createSignDocumentRegister(formData)
            .then(({ data }) => {
              if (data.status) {
                confirmationAlert({
                  titleText: "Tudo Certo",
                  iconText: "success",
                  htmlMessage:  "Documento salvo e assinado digitalmente com sucesso!<br><br>Deseja envia o documento por e-mail para o paciente ?<br><br>",
                  confirmationButtonText: "Sim",
                  cancelButtonText: "Não",
                  isDismissible: false
                }).then((result) => {
                  if (result.value) {
                    confirmationSendMailAlert({
                      titleText: 'Confirme o e-mail do paciente',
                      messageBody: `E-mail do paciente`,
                      confirmationButtonText: 'Enviar',
                      email: patient.email
                    }).then((result) => {
                      if(!!result.value && result.isConfirmed) {
                        sendPatientMail({
                          id_registro: data.id_registro,
                          email: result.value
                        });
                      }
                    });
                  }
                });
                setFields({});
                setTemplateFields({});
                setSelectedDocument("");
                fetchPatientHistory();
                setIsLoadingDynamicInputs(false);
              } else {
                defaultAlert(data.msg_erro, true, "Ok!", "error", data.msg);
                setIsLoadingDynamicInputs(false);
              }
            })
            .catch((error) => {
              setIsLoadingDynamicInputs(false);
            });
        }
      });
      setIsLoadingDynamicInputs(false);
    } else {
      createSignDocumentRegister(formData)
        .then(({ data }) => {
          if (data.status) {
            confirmationAlert({
              titleText: "Tudo Certo",
              iconText: "success",
              htmlMessage:  "Documento salvo e assinado digitalmente com sucesso!<br><br><br>Deseja envia o documento por e-mail para o paciente ?<br><br>",
              confirmationButtonText: "Sim",
              cancelButtonText: "Não",
              isDismissible: false
            }).then((result) => {
              if (result.value) {
                confirmationSendMailAlert({
                  titleText: 'Confirme o e-mail do paciente',
                  messageBody: `E-mail do paciente`,
                  confirmationButtonText: 'Enviar',
                  email: patient.email
                }).then((result) => {
                  if(!!result.value && result.isConfirmed) {
                    sendPatientMail({
                      id_registro: data.id_registro,
                      email: result.value
                    });
                  }
                });
              }
            });
            setFields({});
            setTemplateFields({});
            setSelectedDocument("");
            fetchPatientHistory();
            setIsLoadingDynamicInputs(false);
          } else {
            defaultAlert(data.msg_erro, true, "Ok!", "error", data.msg);
            setIsLoadingDynamicInputs(false);
          }
        })
        .catch((error) => {
          setIsLoadingDynamicInputs(false);
        });
    }
  }

  function handleFinalizeConsult(scheduleId) {
    setIsButtonDisabled(true);
    setFinalizeConsult(scheduleId)
      .then(() => {
        if (!!socket) {
          socket.emit("end_call", { id_sala: roomInternalId });
        }
        defaultAlert("Atendimento Finalizado!").then((result) => {
          if (result.value) {
            history.push("/scheduling/list", {});
          }
        });
      })
      .catch(() => {
        if (!!socket) {
          socket.emit("end_call", { id_sala: roomInternalId });
        }
        setIsButtonDisabled(false);
      });
  }

  return (
    <>
      <Content>
        <Grid
          container
          id="teleconsultBox"
          spacing={2}
          className={classes.consultGrid}
        >
          {isRoomActive ? (
            <>
              {!!videoDomain ?
              (<Grid item xs={12} md={6}>
                {user && Object.keys(user).length !== 0 ? <VideoIframe roomId={id} user={user} dr={true}/> : null}
              </Grid>)
                : null}
              <Grid item xs={12} md={!!videoDomain ? 6 : 12}>
                <Box className={classes.patientInformationsBox}>
                  <Box
                    component={Paper}
                    className={classes.patientInformationData}
                  >
                    <TabContext value={tabValueSelected}>
                      <AppBar
                        className={classes.tabListAppBar}
                        position="static"
                      >
                        <TabList variant="fullWidth" onChange={handleChangeTab}>
                          <Tab label="Atendimento" value="1" />
                          <Tab
                            label="Formulários"
                            value="2"
                            disabled={userData.id_professional ? false : true}
                          />
                          <Tab label="Anexos paciente" value="3"></Tab>
                        </TabList>
                      </AppBar>
                      <TabPanel className={classes.tabStyle} value="1">
                        <Grid container>
                          <Box className={classes.boxPatientInfo}>
                            <Box>
                              <Grid item xs={12} md={12}>
                                <Typography variant="h6" component="p">
                                  {patient?.nome}{" "}
                                  <Typography variant="body1" component="span">
                                    {patient?.nome_social
                                      ? `(${patient?.nome_social})`
                                      : ""}
                                  </Typography>
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <Typography variant="body2" component="p">
                                  <strong>CPF:</strong>
                                  {` ${getCpfFormated(patient.cpf)}`}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <Typography variant="body2" component="p">
                                  <strong>Data de Nascimento:</strong>
                                  {` ${getFormatedDateUI(patient.data_nascimento)}`}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <Typography variant="body2" component="p">
                                  <strong>Idade:</strong>
                                  {` ${getAgeByDate(patient.data_nascimento)}`}
                                </Typography>
                              </Grid>
                            </Box>
                            <Box style={{ alignContent: 'center' }}>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          className={classes.historyPatientTitle}
                        >
                          <Typography variant="h6" component="h2">
                            HISTÓRICO DO PACIENTE
                          </Typography>
                        </Grid>
                        <HistoryTimeline
                          isLoading={isLoading}
                          patientHistory={patientHistory}
                          refresh={() =>  fetchPatientHistory()}
                        />
                      </TabPanel>
                      <TabPanel className={classes.tabStyle} value="2">
                        <Grid container spacing={1}>
                          <Grid item xs={12} md={12}>
                            <FormControl fullWidth style={{ marginTop: 10 }}>
                              <InputLabel>
                                Lista de documentos disponíveis
                              </InputLabel>
                              <Select
                                value={selectedDocument}
                                onChange={handleSelectedDocument}
                              >
                                <MenuItem value="">Selecione</MenuItem>
                                {documents.map((document, index) => (
                                  <MenuItem
                                    key={document.id_documento}
                                    value={document.id_documento}
                                  >
                                    {`${document.titulo} (${document.tipo_de_documento})`}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            className={classes.dynamicFormGrid}
                          >
                            {isLoadingDynamicInputs ? (
                              <Box className={classes.isLoadingDynamicInputBox}>
                                <CircularProgress color="primary" />
                              </Box>
                            ) : (
                              ""
                            )}
                            {!isLoadingDynamicInputs &&
                            Object.keys(templateFields).length === 0 ? (
                              <Box className={classes.recordImageBox}>
                                <img
                                  className={classes.imageNoData}
                                  src={imgRecord}
                                  alt="Imagem que demonstrativa quando não há nenhum campo de documento selecionado"
                                />
                                <Typography variant="h6" component="h6">
                                  Selecione um documento da lista.
                                </Typography>
                              </Box>
                            ) : (
                              ""
                            )}
                            {Object.keys(templateFields).map((field, index) => (
                              <HealthRecordField
                                key={index}
                                disabled={isLoadingDynamicInputs}
                                label={
                                  templateFields[
                                    `${Object.keys(templateFields)[index]}`
                                  ]
                                }
                                onChangeValueText={(value) =>
                                  setFields({
                                    ...fields,
                                    [`${Object.keys(templateFields)[index]}`]:
                                      value,
                                  })
                                }
                                valueText={
                                  fields[
                                    `${Object.keys(templateFields)[index]}`
                                  ]
                                }
                              />
                            ))}
                            {Object.keys(templateFields).length > 0 ? (
                              <Box className={classes.boxFormButtons}>
                                <SaveButton
                                  disabled={isLoadingDynamicInputs}
                                  onClick={handleSubmitDynamicForm}
                                  className={classes.saveFormButton}
                                  size="large"
                                >
                                  Salvar formulário
                                </SaveButton>
                                {signatureServiceStatus &&
                                userCertificateStatus &&
                                canSign ? (
                                  <SaveButton
                                    disabled={isLoadingDynamicInputs}
                                    onClick={handleSubmitSignDynamicForm}
                                    className={classes.saveFormButton}
                                    size="large"
                                  >
                                    Salvar e assinar formulário
                                  </SaveButton>
                                ) : (
                                  ""
                                )}
                              </Box>
                            ) : (
                              ""
                            )}
                          </Grid>
                        </Grid>
                      </TabPanel>
                      <TabPanel value="3" className={classes.tabStyle}>
                        <FileList fileList={files} isLoading={isLoading} />
                      </TabPanel>
                    </TabContext>
                  </Box>
                  {userData.id_professional ? (
                    <Box className={classes.boxButtonFinish}>
                      <SaveButton
                        onClick={() => handleFinalizeConsult(scheduleId)}
                        disabled={isButtonDisabled}
                        size="large"
                        style={{ width: '50%' }}
                      >
                        Finalizar Atendimento
                      </SaveButton>
                      <Button 
                        // color="secondary"
                        style={{ border: '1px solid', borderColor: '#4CAF51' }}
                        variant="outlined" 
                        style={{ width: '50%' }}
                        onClick={() => {
                          window.open('https://assinador.iti.br', '_blank').focus();
                      }}>
                          ACESSAR &nbsp;&nbsp;&nbsp;&nbsp; <img src={govLogo} />
                      </Button>
                    </Box>
                  ) : (
                    <SaveButton
                      onClick={() => history.goBack()}
                      disabled={isButtonDisabled}
                      size="large"
                    >
                      Voltar
                    </SaveButton>
                  )}
                </Box>
              </Grid>
            </>
          ) : (
            ""
          )}
        </Grid>
      </Content>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  consultGrid: {
    width: "100%",
    height: "100%",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(!!videoDomain ? 0 : 2),
  },
  patientInformationsBox: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    width: "100%",
    minHeight: "545px",
    height: "100%",
  },
  patientInformationData: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    marginBottom: theme.spacing(1),
  },
  patientHealthRecordFields: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    height: "100%",
    width: "100%",
  },
  patientPersonalData: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },
  tabListAppBar: {
    zIndex: theme.zIndex.drawer - 200,
  },
  subTabListAppBar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    borderRadius: "5px 5px 0px 0px",
    marginTop: 10,
  },
  subTabItem: {
    padding: 0,
    overflowY: "scroll",
    maxHeight: "240px",
  },
  tabStyle: {
    margin: theme.spacing(1),
    padding: theme.spacing(0),
  },
  historyPatientTitle: {
    textAlign: "center",
    margin: theme.spacing(1, 0),
  },
  gridFieldsHealthRecord: {
    overflowY: "auto",
  },
  buttonBaseStyle: {},
  boxButtons: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  buttons: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width: "100px",
    height: "60px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    marginRight: "5px",
  },
  dynamicFormGrid: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    maxHeight: "400px",
    overflow: "overlay",
  },
  recordImageBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
  imageNoData: {
    width: 150,
  },
  boxFormButtons: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    gap: "10px",
    marginTop: theme.spacing(1),
  },
  isLoadingDynamicInputBox: {
    width: "49%",
    borderRadius: 3,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    position: "absolute",
    backgroundColor: "#c7c7c77d",
    height: "420px",
  },
  boxButtonFinish:{
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    width: "100%",
    gap: "10px"
  },
  boxPatientInfo:{
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    width: "100%"
  }
}));

const SaveButton = withStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    backgroundColor: theme.status.success(),
    "&:hover": {
      backgroundColor: theme.status.success(700),
    },
  },
}))(Button);
