import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  FormHelperText,
  Input,
  CircularProgress,
  Backdrop,
  Divider,
  MenuItem,
  Select,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import InputMask from "react-input-mask";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import Content from "../../../components/Content";
import { CreateSettingScheduleSchema } from "../../../helpers/validation/Schemas/SettingScheduleSchema/schema";
import { getAllProcedure } from "../../../services/endpoints/procedures/endpoints";
import { getProfessional } from "../../../services/endpoints/professionals/endpoints";
import {
  createSettingsSchedule,
  updateSettingsSchedule,
  getSettingsScheduleId,
  getCheckDateScheduleProfessional,
} from "../../../services/endpoints/settingsSchedule/endpoints";
import {
  defaultAlert,
  confirmationAlert,
} from "../../../helpers/validation/alerts";
import {
  getFormatedDateSystem,
  getFormatedDateUI,
} from "../../../helpers/dateHelpers";
import { useUserData } from "../../../context/UserContext";

export default function CreateSettingSchedule(props) {
  const history = useHistory();
  const { id } = useParams();
  const { pathname } = useLocation();
  const { userData } = useUserData();
  const [
    userIsHelthcareProfessional,
    setUserIsHelthcareProfessional,
  ] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [procedures, setProcedures] = useState([]);
  const [professionals, setProfessionals] = useState([]);

  const classes = useStyles();

  const {
    register,
    errors,
    handleSubmit,
    control,
    setValue,
    reset,
    setError,
    watch,
    formState,
  } = useForm({
    validationSchema: CreateSettingScheduleSchema,
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {
      id_prestador: "",
      data_inicial: "",
      data_final: "",
      tipo_de_escala: "",
      domingo: false,
      segunda: false,
      terca: false,
      quarta: false,
      quinta: false,
      sexta: false,
      sabado: false,
      observacao: "",
      horario_inicial_domingo: "",
      horario_final_domingo: "",
      duracao_domingo: "",
      id_procedimento_domingo: "",
      horario_inicial_segunda: "",
      horario_final_segunda: "",
      duracao_segunda: "",
      id_procedimento_segunda: "",
      horario_inicial_terca: "",
      horario_final_terca: "",
      duracao_terca: "",
      id_procedimento_terca: "",
      horario_inicial_quarta: "",
      horario_final_quarta: "",
      duracao_quarta: "",
      id_procedimento_quarta: "",
      horario_inicial_quinta: "",
      horario_final_quinta: "",
      duracao_quinta: "",
      id_procedimento_quinta: "",
      horario_inicial_sexta: "",
      horario_final_sexta: "",
      duracao_sexta: "",
      id_procedimento_sexta: "",
      horario_inicial_sabado: "",
      horario_final_sabado: "",
      duracao_sabado: "",
      id_procedimento_sabado: "",
    },
  });

  useEffect(() => {
    if (id && pathname === `/schedule/edit/${id}`) {
      setIsLoading(true);
      setIsEditing(true);
      const handleFetchSettingSchedule = async () => {
        await getSettingsScheduleId(id)
          .then(({ data }) => {
            const {
              id_prestador,
              data_inicial,
              data_final,
              domingo,
              segunda,
              terca,
              quarta,
              quinta,
              sexta,
              sabado,
              observacao,
              tipo_de_escala,
              horario_inicial_domingo,
              horario_final_domingo,
              duracao_domingo,
              id_procedimento_domingo,
              horario_inicial_segunda,
              horario_final_segunda,
              duracao_segunda,
              id_procedimento_segunda,
              horario_inicial_terca,
              horario_final_terca,
              duracao_terca,
              id_procedimento_terca,
              horario_inicial_quarta,
              horario_final_quarta,
              duracao_quarta,
              id_procedimento_quarta,
              horario_inicial_quinta,
              horario_final_quinta,
              duracao_quinta,
              id_procedimento_quinta,
              horario_inicial_sexta,
              horario_final_sexta,
              duracao_sexta,
              id_procedimento_sexta,
              horario_inicial_sabado,
              horario_final_sabado,
              duracao_sabado,
              id_procedimento_sabado,
            } = data.agenda;
            setValue([
              { id_prestador },
              { data_inicial },
              { data_final },
              { domingo },
              { segunda },
              { terca },
              { quarta },
              { quinta },
              { sexta },
              { sabado },
              { observacao },
              { tipo_de_escala },
              { horario_inicial_domingo },
              { horario_final_domingo },
              { duracao_domingo },
              { id_procedimento_domingo },
              { horario_inicial_segunda },
              { horario_final_segunda },
              { duracao_segunda },
              { id_procedimento_segunda },
              { horario_inicial_terca },
              { horario_final_terca },
              { duracao_terca },
              { id_procedimento_terca },
              { horario_inicial_quarta },
              { horario_final_quarta },
              { duracao_quarta },
              { id_procedimento_quarta },
              { horario_inicial_quinta },
              { horario_final_quinta },
              { duracao_quinta },
              { id_procedimento_quinta },
              { horario_inicial_sexta },
              { horario_final_sexta },
              { duracao_sexta },
              { id_procedimento_sexta },
              { horario_inicial_sabado },
              { horario_final_sabado },
              { duracao_sabado },
              { id_procedimento_sabado },
            ]);
          })
          .catch(() => {
            history.push("/schedule/list");
            setIsEditing(false);
          });
        setIsLoading(false);
      };
      handleFetchSettingSchedule();
    }
    return () => {};
  }, [id, history, setValue, pathname]);

  useEffect(() => {
    if (userData.is_healthcare_professional) {
      setUserIsHelthcareProfessional(true);
      setValue("id_prestador", userData.id_professional);
    }
  }, [userData]);

  const watchIdProfessional = watch("id_prestador");

  const watchSunDay = watch("domingo");
  const watchMonDay = watch("segunda");
  const watchTueDay = watch("terca");
  const watchWedDay = watch("quarta");
  const watchThuDay = watch("quinta");
  const watchFriDay = watch("sexta");
  const watchSatDay = watch("sabado");

  const { dirty } = formState;

  useEffect(() => {
    const fetchAllProfessionals = async () => {
      await getProfessional()
        .then(({ data }) => {
          setProfessionals(data.list_prestador);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    };
    fetchAllProfessionals();
    return () => {};
  }, []);

  useEffect(() => {
    const fetchProcedures = async () => {
      await getAllProcedure()
        .then(({ data }) => {
          setProcedures(data.list);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    };
    fetchProcedures();
    return () => {};
  }, []);

  async function handleForm(data) {
    setIsLoading(true);
    const settingScheduleFormData = {
      id_prestador: data.id_prestador,
      data_inicial: data.data_inicial,
      data_final: data.data_final,
      tipo_de_escala: data.tipo_de_escala,
      domingo: data.domingo,
      segunda: data.segunda,
      terca: data.terca,
      quarta: data.quarta,
      quinta: data.quinta,
      sexta: data.sexta,
      sabado: data.sabado,
      observacao: data.observacao,
      dias: [
        {
          atende: data.domingo,
          dia: "Sun",
          int_dia: 0,
          hora_inicial: data.horario_inicial_domingo,
          hora_final: data.horario_final_domingo,
          duracao: data.duracao_domingo,
          id_procedimento: data.id_procedimento_domingo,
        },
        {
          atende: data.segunda,
          dia: "Mon",
          int_dia: 1,
          hora_inicial: data.horario_inicial_segunda,
          hora_final: data.horario_final_segunda,
          duracao: data.duracao_segunda,
          id_procedimento: data.id_procedimento_segunda,
        },
        {
          atende: data.terca,
          dia: "Tue",
          int_dia: 2,
          hora_inicial: data.horario_inicial_terca,
          hora_final: data.horario_final_terca,
          duracao: data.duracao_terca,
          id_procedimento: data.id_procedimento_terca,
        },
        {
          atende: data.quarta,
          dia: "Wed",
          int_dia: 3,
          hora_inicial: data.horario_inicial_quarta,
          hora_final: data.horario_final_quarta,
          duracao: data.duracao_quarta,
          id_procedimento: data.id_procedimento_quarta,
        },
        {
          atende: data.quinta,
          dia: "Thu",
          int_dia: 4,
          hora_inicial: data.horario_inicial_quinta,
          hora_final: data.horario_final_quinta,
          duracao: data.duracao_quinta,
          id_procedimento: data.id_procedimento_quinta,
        },
        {
          atende: data.sexta,
          dia: "Fri",
          int_dia: 5,
          hora_inicial: data.horario_inicial_sexta,
          hora_final: data.horario_final_sexta,
          duracao: data.duracao_sexta,
          id_procedimento: data.id_procedimento_sexta,
        },
        {
          atende: data.sabado,
          dia: "Sat",
          int_dia: 6,
          hora_inicial: data.horario_inicial_sabado,
          hora_final: data.horario_final_sabado,
          duracao: data.duracao_sabado,
          id_procedimento: data.id_procedimento_sabado,
        },
      ],
    };
    if (!isEditing) {
      const data = await handleCreateSettingSchedule(settingScheduleFormData);
    } else {
      const data = await handleUpdateSettingSchedule(
        id,
        settingScheduleFormData
      );
    }
  }

  async function handleCreateSettingSchedule(dataForm) {
    await getCheckDateScheduleProfessional(
      dataForm.id_prestador,
      getFormatedDateSystem(dataForm.data_inicial)
    )
      .then(({ data }) => {
        if (data.status) {
          setIsLoading(false);
          confirmationAlert({
            titleText: "Já existe uma agenda configurada para esta data?",
            htmlMessage: `<div style="text-align:center">
                    <strong>Informações da agenda:</strong>
                    <p>
                      <span><strong>Data incial:</strong> ${getFormatedDateUI(
                        data.agenda.data_inicial
                      )}</span></br>
                      <span><strong>Data final:</strong> ${getFormatedDateUI(
                        data.agenda.data_final
                      )}</span></br>
                      <br><br>
                      <span><strong>Deseja continuar:</strong></br>
                    <p>
                    </div>
                    `,
            footerMessage:
              "<div style='text-align:center'><i>A agenda corrente sera desativada caso você prossiga!</i></div>",
          }).then(async (result) => {
            if (result.value) {
              setIsLoading(true);
              await createSettingsSchedule(dataForm)
                .then(({ data }) => {
                  if (data.status) {
                    defaultAlert(
                      "Agenda criada com Sucesso!",
                      false,
                      "Ver lista de agendas",
                      "success",
                      "Tudo certo!"
                    ).then((result) => {
                      if (result.value) {
                        history.push("/schedule/list");
                      }
                    });
                  }
                })
                .catch((error) => {
                  if (error.response.status === 400) {
                    let { errors } = error.response.data;
                    Object.keys(errors).map((erro) => {
                      return setError(erro, "notMatch", errors[erro]);
                    });
                    setIsLoading(false);
                    return false;
                  }
                });
              setIsLoading(false);
            }
          });
        } else {
          createSettingsSchedule(dataForm)
            .then(({ data }) => {
              if (data.status) {
                defaultAlert(
                  "Agenda criada com Sucesso!",
                  false,
                  "Ver lista de agendas",
                  "success",
                  "Tudo certo!"
                ).then((result) => {
                  if (result.value) {
                    history.push("/schedule/list");
                  }
                });
              }
            })
            .catch((error) => {
              if (error.response.status === 400) {
                let { errors } = error.response.data;
                Object.keys(errors).map((erro) => {
                  return setError(erro, "notMatch", errors[erro]);
                });
                setIsLoading(false);
                return false;
              }
            });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }

  async function handleUpdateSettingSchedule(idSchedule, scheduleFormData) {
    setIsLoading(true);
    return updateSettingsSchedule(idSchedule, scheduleFormData)
      .then(({ data }) => {
        if (data.status) {
          defaultAlert(
            "Agenda alterada com Sucesso!",
            false,
            "Ver lista de agendas",
            "success",
            "Tudo certo!"
          ).then((result) => {
            if (result.value) {
              history.push("/schedule/list");
            }
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          let { errors } = error.response.data;
          Object.keys(errors).map((erro) => {
            return setError(erro, "notMatch", errors[erro]);
          });
          setIsLoading(false);
          return false;
        }
      });
  }

  function handleCheckDateScheduleProfessional(idsProfessional, date) {
    setIsLoading(true);
    getCheckDateScheduleProfessional(idsProfessional, date)
      .then(({ data }) => {
        if (data.status) {
          confirmationAlert({
            titleText: "Já existe uma agenda configurada para esta data?",
            htmlMessage: `<div style="text-align:center">
                    <strong>Informações da agenda:</strong>
                    <p>
                      <span><strong>Data incial:</strong> ${getFormatedDateUI(
                        data.agenda.data_inicial
                      )}</span></br>
                      <span><strong>Data final:</strong> ${getFormatedDateUI(
                        data.agenda.data_final
                      )}</span></br>
                      <br><br>
                      <span><strong>Deseja continuar:</strong></br>
                    <p>
                    </div>
                    `,
            footerMessage:
              "<div style='text-align:center'><i>A agenda corrente sera desativada caso você prossiga!</i></div>",
          }).then((result) => {
            if (result.value) {
              setIsLoading(true);
            }
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }

  return (
    <>
      <Content formIsDirty={dirty}>
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid container className={classes.gridRoot}>
          <Box className={classes.paperStyle} component={Paper} elevation={2}>
            <Typography component="h1" variant="h6">
              {isEditing ? "Editar agenda" : "Nova Agenda"}
            </Typography>
            <form
              method="POST"
              onSubmit={handleSubmit((data) => handleForm(data))}
            >
              <Box className={classes.flexContainer}>
                <FormControl
                  error={!!errors.id_prestador}
                  className={classes.flexItems}
                >
                  <InputLabel>Profissional de saúde</InputLabel>
                  <Controller
                    as={
                      <Select
                        disabled={isEditing || userIsHelthcareProfessional}
                      >
                        <MenuItem value={""}>Selecione</MenuItem>
                        {professionals.map((type, index) => (
                          <MenuItem
                            key={type.id_prestador}
                            value={type.id_prestador}
                          >
                            {type.prestador}
                          </MenuItem>
                        ))}
                      </Select>
                    }
                    control={control}
                    name="id_prestador"
                  />
                  <FormHelperText>
                    {errors?.id_prestador?.message}
                  </FormHelperText>
                </FormControl>
              </Box>
              <div
                className={watchIdProfessional === "" ? classes.invisible : ""}
              >
                <Divider className={classes.dividerSpacing} />
                <Typography variant="button" display="block" gutterBottom>
                  Configurações da agenda
                </Typography>
                <Box className={classes.flexContainer}>
                  <FormControl
                    error={!!errors.data_inicial}
                    className={classes.flexItems}
                  >
                    <InputLabel shrink={true}>Data inicial</InputLabel>
                    <Input
                      name="data_inicial"
                      type="date"
                      inputProps={{
                        min: "01/01/2020",
                        max: new Date().toISOString().split("T")[0],
                      }}
                      inputRef={register()}
                    />
                    <FormHelperText>
                      {errors?.data_inicial?.message}
                    </FormHelperText>
                  </FormControl>
                  <FormControl
                    error={!!errors.data_final}
                    className={classes.flexItems}
                  >
                    <InputLabel shrink={true}>Data final</InputLabel>
                    <Input
                      name="data_final"
                      type="date"
                      inputProps={{
                        min: "01/01/2020",
                        max: new Date(
                          `${parseInt(new Date().getFullYear()) + 2}`
                        )
                          .toISOString()
                          .split("T")[0],
                      }}
                      inputRef={register()}
                    />
                    <FormHelperText>
                      {errors?.data_final?.message}
                    </FormHelperText>
                  </FormControl>
                  <FormControl
                    error={!!errors.tipo_de_escala}
                    className={classes.flexItems}
                  >
                    <InputLabel>Tipo de escala</InputLabel>
                    <Controller
                      as={
                        <Select>
                          <MenuItem value={""}>Selecione</MenuItem>
                          <MenuItem key={0} value={0}>
                            Por duração de atendimento
                          </MenuItem>
                        </Select>
                      }
                      control={control}
                      name="tipo_de_escala"
                    />
                  </FormControl>
                </Box>
                <Box className={classes.flexContainer}>
                  <FormControl
                    error={!!errors.observacao}
                    className={classes.flexItems}
                  >
                    <InputLabel>Observação</InputLabel>
                    <Controller
                      as={<Input />}
                      control={control}
                      name="observacao"
                    />
                    <FormHelperText>
                      {errors?.observacao?.message}
                    </FormHelperText>
                  </FormControl>
                </Box>
                <Divider className={classes.dividerSpacing} />
                <Typography variant="button" display="block" gutterBottom>
                  Tabela de dias e horários
                </Typography>
                <Box className={classes.flexContainer}>
                  <TableContainer component={Paper}>
                    <Table
                      className={classes.table}
                      aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="center">
                            Dia da semana
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Atende
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Horário inicial
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Horário final
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Duração (em minutos)
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Procedimento
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key={"Sun"}>
                          <StyledTableCell align="center">
                            Domingo
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <FormControl
                              error={!!errors.domingo}
                              className={classes.checkItems}
                            >
                              <Input
                                name="domingo"
                                type="checkbox"
                                inputRef={register()}
                                disableUnderline={true}
                              />
                            </FormControl>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <FormControl
                              error={!!errors.horario_inicial_domingo}
                              className={classes.flexItems}
                            >
                              <Input
                                name="horario_inicial_domingo"
                                type="time"
                                inputRef={register({
                                  required: watchSunDay ? false : true,
                                })}
                                readOnly={watchSunDay ? false : true}
                              />
                              <FormHelperText>
                                {errors?.horario_inicial_domingo?.message}
                              </FormHelperText>
                            </FormControl>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <FormControl
                              error={!!errors.horario_final_domingo}
                              className={classes.flexItems}
                            >
                              <Input
                                name="horario_final_domingo"
                                type="time"
                                inputRef={register()}
                                readOnly={watchSunDay ? false : true}
                              />
                              <FormHelperText>
                                {errors?.horario_final_domingo?.message}
                              </FormHelperText>
                            </FormControl>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <FormControl
                              error={!!errors.duracao_domingo}
                              className={classes.flexItems}
                            >
                              <Input
                                name="duracao_domingo"
                                type="number"
                                inputRef={register()}
                                readOnly={watchSunDay ? false : true}
                              />
                              <FormHelperText>
                                {errors?.duracao_domingo?.message}
                              </FormHelperText>
                            </FormControl>
                          </StyledTableCell>
                          <StyledTableCell>
                            <FormControl
                              error={!!errors.id_procedimento_domingo}
                              className={classes.flexItems}
                            >
                              <Controller
                                as={
                                  <Select disabled={watchSunDay ? false : true}>
                                    <MenuItem value={""}>Selecione</MenuItem>
                                    {procedures.map((type, index) => (
                                      <MenuItem
                                        key={type.id_procedimento}
                                        value={type.id_procedimento}
                                      >
                                        {type.nome}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                }
                                control={control}
                                name="id_procedimento_domingo"
                              />
                              <FormHelperText>
                                {errors?.id_procedimento_domingo?.message}
                              </FormHelperText>
                            </FormControl>
                          </StyledTableCell>
                        </TableRow>
                        <TableRow key={"Mon"}>
                          <StyledTableCell align="center">
                            Segunda-feira
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <FormControl
                              error={!!errors.segunda}
                              className={classes.checkItems}
                            >
                              <Input
                                name="segunda"
                                type="checkbox"
                                inputRef={register()}
                                disableUnderline={true}
                              />
                            </FormControl>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <FormControl
                              error={!!errors.horario_inicial_segunda}
                              className={classes.flexItems}
                            >
                              <Input
                                name="horario_inicial_segunda"
                                type="time"
                                inputRef={register()}
                                readOnly={watchMonDay ? false : true}
                              />
                              <FormHelperText>
                                {errors?.horario_inicial_segunda?.message}
                              </FormHelperText>
                            </FormControl>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <FormControl
                              error={!!errors.horario_final_segunda}
                              className={classes.flexItems}
                            >
                              <Input
                                name="horario_final_segunda"
                                type="time"
                                inputRef={register()}
                                readOnly={watchMonDay ? false : true}
                              />
                              <FormHelperText>
                                {errors?.horario_final_segunda?.message}
                              </FormHelperText>
                            </FormControl>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <FormControl
                              error={!!errors.duracao_segunda}
                              className={classes.flexItems}
                            >
                              <Input
                                name="duracao_segunda"
                                type="number"
                                inputRef={register()}
                                readOnly={watchMonDay ? false : true}
                              />
                              <FormHelperText>
                                {errors?.duracao_segunda?.message}
                              </FormHelperText>
                            </FormControl>
                          </StyledTableCell>
                          <StyledTableCell>
                            <FormControl
                              error={!!errors.id_procedimento_segunda}
                              className={classes.flexItems}
                            >
                              <Controller
                                as={
                                  <Select disabled={watchMonDay ? false : true}>
                                    <MenuItem value={""}>Selecione</MenuItem>
                                    {procedures.map((type, index) => (
                                      <MenuItem
                                        key={type.id_procedimento}
                                        value={type.id_procedimento}
                                      >
                                        {type.nome}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                }
                                control={control}
                                name="id_procedimento_segunda"
                              />
                              <FormHelperText>
                                {errors?.id_procedimento_segunda?.message}
                              </FormHelperText>
                            </FormControl>
                          </StyledTableCell>
                        </TableRow>
                        <TableRow key={"Tue"}>
                          <StyledTableCell align="center">
                            Terça-feira
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <FormControl
                              error={!!errors.terca}
                              className={classes.checkItems}
                            >
                              <Input
                                name="terca"
                                type="checkbox"
                                inputRef={register()}
                                disableUnderline={true}
                              />
                            </FormControl>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <FormControl
                              error={!!errors.horario_inicial_terca}
                              className={classes.flexItems}
                            >
                              <Input
                                name="horario_inicial_terca"
                                type="time"
                                inputRef={register()}
                                readOnly={watchTueDay ? false : true}
                              />
                              <FormHelperText>
                                {errors?.horario_inicial_terca?.message}
                              </FormHelperText>
                            </FormControl>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <FormControl
                              error={!!errors.horario_final_terca}
                              className={classes.flexItems}
                            >
                              <Input
                                name="horario_final_terca"
                                type="time"
                                inputRef={register()}
                                readOnly={watchTueDay ? false : true}
                              />
                              <FormHelperText>
                                {errors?.horario_final_terca?.message}
                              </FormHelperText>
                            </FormControl>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <FormControl
                              error={!!errors.duracao_terca}
                              className={classes.flexItems}
                            >
                              <Input
                                name="duracao_terca"
                                type="number"
                                inputRef={register()}
                                readOnly={watchTueDay ? false : true}
                              />
                              <FormHelperText>
                                {errors?.duracao_terca?.message}
                              </FormHelperText>
                            </FormControl>
                          </StyledTableCell>
                          <StyledTableCell>
                            <FormControl
                              error={!!errors.id_procedimento_terca}
                              className={classes.flexItems}
                            >
                              <Controller
                                as={
                                  <Select disabled={watchTueDay ? false : true}>
                                    <MenuItem value={""}>Selecione</MenuItem>
                                    {procedures.map((type, index) => (
                                      <MenuItem
                                        key={type.id_procedimento}
                                        value={type.id_procedimento}
                                      >
                                        {type.nome}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                }
                                control={control}
                                name="id_procedimento_terca"
                              />
                              <FormHelperText>
                                {errors?.id_procedimento_terca?.message}
                              </FormHelperText>
                            </FormControl>
                          </StyledTableCell>
                        </TableRow>
                        <TableRow key={"Wed"}>
                          <StyledTableCell align="center">
                            Quarta-feira
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <FormControl
                              error={!!errors.quarta}
                              className={classes.checkItems}
                            >
                              <Input
                                name="quarta"
                                type="checkbox"
                                inputRef={register()}
                                disableUnderline={true}
                              />
                            </FormControl>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <FormControl
                              error={!!errors.horario_inicial_quarta}
                              className={classes.flexItems}
                            >
                              <Input
                                name="horario_inicial_quarta"
                                type="time"
                                inputRef={register()}
                                readOnly={watchWedDay ? false : true}
                              />
                              <FormHelperText>
                                {errors?.horario_inicial_quarta?.message}
                              </FormHelperText>
                            </FormControl>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <FormControl
                              error={!!errors.horario_final_quarta}
                              className={classes.flexItems}
                            >
                              <Input
                                name="horario_final_quarta"
                                type="time"
                                inputRef={register()}
                                readOnly={watchWedDay ? false : true}
                              />
                              <FormHelperText>
                                {errors?.horario_final_quarta?.message}
                              </FormHelperText>
                            </FormControl>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <FormControl
                              error={!!errors.duracao_quarta}
                              className={classes.flexItems}
                            >
                              <Input
                                name="duracao_quarta"
                                type="number"
                                inputRef={register()}
                                readOnly={watchWedDay ? false : true}
                              />
                              <FormHelperText>
                                {errors?.duracao_quarta?.message}
                              </FormHelperText>
                            </FormControl>
                          </StyledTableCell>
                          <StyledTableCell>
                            <FormControl
                              error={!!errors.id_procedimento_quarta}
                              className={classes.flexItems}
                            >
                              <Controller
                                as={
                                  <Select disabled={watchWedDay ? false : true}>
                                    <MenuItem value={""}>Selecione</MenuItem>
                                    {procedures.map((type, index) => (
                                      <MenuItem
                                        key={type.id_procedimento}
                                        value={type.id_procedimento}
                                      >
                                        {type.nome}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                }
                                control={control}
                                name="id_procedimento_quarta"
                              />
                              <FormHelperText>
                                {errors?.id_procedimento_quarta?.message}
                              </FormHelperText>
                            </FormControl>
                          </StyledTableCell>
                        </TableRow>
                        <TableRow key={"Thu"}>
                          <StyledTableCell align="center">
                            Quinta-feira
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <FormControl
                              error={!!errors.quinta}
                              className={classes.checkItems}
                            >
                              <Input
                                name="quinta"
                                type="checkbox"
                                inputRef={register()}
                                disableUnderline={true}
                              />
                            </FormControl>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <FormControl
                              error={!!errors.horario_inicial_quinta}
                              className={classes.flexItems}
                            >
                              <Input
                                name="horario_inicial_quinta"
                                type="time"
                                inputRef={register()}
                                readOnly={watchThuDay ? false : true}
                              />
                              <FormHelperText>
                                {errors?.horario_inicial_quinta?.message}
                              </FormHelperText>
                            </FormControl>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <FormControl
                              error={!!errors.horario_final_quinta}
                              className={classes.flexItems}
                            >
                              <Input
                                name="horario_final_quinta"
                                type="time"
                                inputRef={register()}
                                readOnly={watchThuDay ? false : true}
                              />
                              <FormHelperText>
                                {errors?.horario_final_quinta?.message}
                              </FormHelperText>
                            </FormControl>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <FormControl
                              error={!!errors.duracao_quinta}
                              className={classes.flexItems}
                            >
                              <Input
                                name="duracao_quinta"
                                type="number"
                                inputRef={register()}
                                readOnly={watchThuDay ? false : true}
                              />
                              <FormHelperText>
                                {errors?.duracao_quinta?.message}
                              </FormHelperText>
                            </FormControl>
                          </StyledTableCell>
                          <StyledTableCell>
                            <FormControl
                              error={!!errors.id_procedimento_quinta}
                              className={classes.flexItems}
                            >
                              <Controller
                                as={
                                  <Select disabled={watchThuDay ? false : true}>
                                    <MenuItem value={""}>Selecione</MenuItem>
                                    {procedures.map((type, index) => (
                                      <MenuItem
                                        key={type.id_procedimento}
                                        value={type.id_procedimento}
                                      >
                                        {type.nome}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                }
                                control={control}
                                name="id_procedimento_quinta"
                              />
                              <FormHelperText>
                                {errors?.id_procedimento_quinta?.message}
                              </FormHelperText>
                            </FormControl>
                          </StyledTableCell>
                        </TableRow>
                        <TableRow key={"Fri"}>
                          <StyledTableCell align="center">
                            Sexta-feira
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <FormControl
                              error={!!errors.sexta}
                              className={classes.checkItems}
                            >
                              <Input
                                name="sexta"
                                type="checkbox"
                                inputRef={register()}
                                disableUnderline={true}
                              />
                            </FormControl>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <FormControl
                              error={!!errors.horario_inicial_sexta}
                              className={classes.flexItems}
                            >
                              <Input
                                name="horario_inicial_sexta"
                                type="time"
                                inputRef={register()}
                                readOnly={watchFriDay ? false : true}
                              />
                              <FormHelperText>
                                {errors?.horario_inicial_sexta?.message}
                              </FormHelperText>
                            </FormControl>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <FormControl
                              error={!!errors.horario_final_sexta}
                              className={classes.flexItems}
                            >
                              <Input
                                name="horario_final_sexta"
                                type="time"
                                inputRef={register()}
                                readOnly={watchFriDay ? false : true}
                              />
                              <FormHelperText>
                                {errors?.horario_final_sexta?.message}
                              </FormHelperText>
                            </FormControl>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <FormControl
                              error={!!errors.duracao_sexta}
                              className={classes.flexItems}
                            >
                              <Input
                                name="duracao_sexta"
                                type="number"
                                inputRef={register()}
                                readOnly={watchFriDay ? false : true}
                              />
                              <FormHelperText>
                                {errors?.duracao_sexta?.message}
                              </FormHelperText>
                            </FormControl>
                          </StyledTableCell>
                          <StyledTableCell>
                            <FormControl
                              error={!!errors.id_procedimento_sexta}
                              className={classes.flexItems}
                            >
                              <Controller
                                as={
                                  <Select disabled={watchFriDay ? false : true}>
                                    <MenuItem value={""}>Selecione</MenuItem>
                                    {procedures.map((type, index) => (
                                      <MenuItem
                                        key={type.id_procedimento}
                                        value={type.id_procedimento}
                                      >
                                        {type.nome}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                }
                                control={control}
                                name="id_procedimento_sexta"
                              />
                              <FormHelperText>
                                {errors?.id_procedimento_sexta?.message}
                              </FormHelperText>
                            </FormControl>
                          </StyledTableCell>
                        </TableRow>
                        <TableRow key={"Sat"}>
                          <StyledTableCell align="center">
                            Sábado
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <FormControl
                              error={!!errors.sabado}
                              className={classes.checkItems}
                            >
                              <Input
                                name="sabado"
                                type="checkbox"
                                inputRef={register()}
                                disableUnderline={true}
                              />
                            </FormControl>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <FormControl
                              error={!!errors.horario_inicial_sabado}
                              className={classes.flexItems}
                            >
                              <Input
                                name="horario_inicial_sabado"
                                type="time"
                                inputRef={register()}
                                readOnly={watchSatDay ? false : true}
                              />
                              <FormHelperText>
                                {errors?.horario_inicial_sabado?.message}
                              </FormHelperText>
                            </FormControl>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <FormControl
                              error={!!errors.horario_final_sabado}
                              className={classes.flexItems}
                            >
                              <Input
                                name="horario_final_sabado"
                                type="time"
                                inputRef={register()}
                                readOnly={watchSatDay ? false : true}
                              />
                              <FormHelperText>
                                {errors?.horario_final_sabado?.message}
                              </FormHelperText>
                            </FormControl>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <FormControl
                              error={!!errors.duracao_sabado}
                              className={classes.flexItems}
                            >
                              <Input
                                name="duracao_sabado"
                                type="number"
                                inputRef={register()}
                                readOnly={watchSatDay ? false : true}
                              />
                              <FormHelperText>
                                {errors?.duracao_sabado?.message}
                              </FormHelperText>
                            </FormControl>
                          </StyledTableCell>
                          <StyledTableCell>
                            <FormControl
                              error={!!errors.id_procedimento_sabado}
                              className={classes.flexItems}
                            >
                              <Controller
                                as={
                                  <Select disabled={watchSatDay ? false : true}>
                                    <MenuItem value={""}>Selecione</MenuItem>
                                    {procedures.map((type, index) => (
                                      <MenuItem
                                        key={type.id_procedimento}
                                        value={type.id_procedimento}
                                      >
                                        {type.nome}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                }
                                control={control}
                                name="id_procedimento_sabado"
                              />
                              <FormHelperText>
                                {errors?.id_procedimento_sabado?.message}
                              </FormHelperText>
                            </FormControl>
                          </StyledTableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                <Box className={classes.flexContainerButton}>
                  <Button
                    size="large"
                    variant="contained"
                    type="submit"
                    color="primary"
                  >
                    {isEditing ? "Atualizar agenda" : "Cadastrar agenda"}
                  </Button>
                </Box>
              </div>
            </form>
          </Box>
        </Grid>
      </Content>
    </>
  );
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.action.disabledBackground,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  gridRoot: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
  },
  paperStyle: {
    padding: theme.spacing(2),
    margin: theme.spacing(0, 2),
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  flexContainer: {
    marginTop: theme.spacing(2),
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    justifyContent: "space-between",
  },
  flexContainerButton: {
    marginTop: theme.spacing(5),
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    justifyContent: "flex-start",
  },
  flexItems: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.between("xs", "sm")]: {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(2),
    },
    width: "100%",
  },
  checkItems: {
    width: "100%",
  },
  dividerSpacing: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  invisible: {
    display: "none",
  },
}));
